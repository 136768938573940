@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

/*
Bootstrap theme
 */
$primary:       #bf0d25;
$success:       #419641;
$info:          #2aabd2;
$light:         #ffffff;

$c1: #BF0C25;
$c2: #F17F29;
$c3: #D1D1D1;
$c4: #973547;
$c5: #222725;

// 14px
$font-size-base: 0.875rem;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.35rem;

// Components
//
// Define common padding and border radius sizes and more.
$component-active-bg: $info;


// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-link-active-bg: $primary;

@import 'bootstrap/scss/bootstrap';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

html, body { height: 100%; min-width: 960px;}
body {
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.app-wrapper {
    padding-bottom: 40px;
    min-height: 100%;
    margin: 0 auto -42px 0;
}

.app-content {
    padding-top: 90px; /* Navbar height(50) + navbar company line(25) + margin bottom(15) */
}

.app-footer {
    height: 40px;
}


/*
Material
 */

@import '@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

$fontConfig: (
        display-4: mat-typography-level(112px, 112px, 300, "Helvetica Neue, Helvetica, Arial, sans-serif", -0.0134em),
        display-3: mat-typography-level(56px, 56px, 400, "Helvetica Neue, Helvetica, Arial, sans-serif", -0.0089em),
        display-2: mat-typography-level(45px, 48px, 400, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0000em),
        display-1: mat-typography-level(34px, 40px, 400, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0074em),
        headline: mat-typography-level(24px, 32px, 400, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0000em),
        title: mat-typography-level(20px, 32px, 500, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0075em),
        subheading-2: mat-typography-level(16px, 28px, 400, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0094em),
        subheading-1: mat-typography-level(15px, 24px, 500, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0067em),
        body-2: mat-typography-level(14px, 24px, 500, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0179em),
        body-1: mat-typography-level(14px, 20px, 400, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0179em),
        button: mat-typography-level(14px, 14px, 500, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0893em),
        caption: mat-typography-level(12px, 20px, 400, "Helvetica Neue, Helvetica, Arial, sans-serif", 0.0333em),
        input: mat-typography-level(inherit, 1.125, 400, "Helvetica Neue, Helvetica, Arial, sans-serif")
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
        base:              black,
        divider:           $dark-dividers,
        dividers:          $dark-dividers,
        disabled:          $dark-disabled-text,
        disabled-button:   rgba($dark-text, 0.26),
        disabled-text:     $dark-disabled-text,
        elevation:         black,
        secondary-text:    $dark-accent-text,
        hint-text:         $dark-disabled-text,
        accent-text:       $dark-accent-text,
        icon:              $dark-accent-text,
        icons:             $dark-accent-text,
        text:              $dark-primary-text,
        slider-min:        $dark-primary-text,
        slider-off:        rgba($dark-text, 0.26),
        slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
        base:              $light-text,
        divider:           $light-dividers,
        dividers:          $light-dividers,
        disabled:          $light-disabled-text,
        disabled-button:   rgba($light-text, 0.3),
        disabled-text:     $light-disabled-text,
        elevation:         black,
        hint-text:         $light-disabled-text,
        secondary-text:    $light-accent-text,
        accent-text:       $light-accent-text,
        icon:              $light-text,
        icons:             $light-text,
        text:              $light-text,
        slider-min:        $light-text,
        slider-off:        rgba($light-text, 0.3),
        slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
        background:               $light-background,
        status-bar:               $light-bg-darker-20,
        app-bar:                  $light-bg-darker-5,
        hover:                    $dark-bg-alpha-4,
        card:                     $light-bg-lighter-5,
        dialog:                   $light-bg-lighter-5,
        tooltip:                  $dark-bg-tooltip,
        disabled-button:          $dark-bg-alpha-12,
        raised-button:            $light-bg-lighter-5,
        focused-button:           $dark-focused,
        selected-button:          $light-bg-darker-20,
        selected-disabled-button: $light-bg-darker-30,
        disabled-button-toggle:   $light-bg-darker-10,
        unselected-chip:          $light-bg-darker-10,
        disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
        background:               $dark-background,
        status-bar:               $dark-bg-lighter-20,
        app-bar:                  $dark-bg-lighter-5,
        hover:                    $light-bg-alpha-4,
        card:                     $dark-bg-lighter-5,
        dialog:                   $dark-bg-lighter-5,
        tooltip:                  $dark-bg-lighter-20,
        disabled-button:          $light-bg-alpha-12,
        raised-button:            $dark-bg-lighter-5,
        focused-button:           $light-focused,
        selected-button:          $dark-bg-lighter-20,
        selected-disabled-button: $dark-bg-lighter-30,
        disabled-button-toggle:   $dark-bg-lighter-10,
        unselected-chip:          $dark-bg-lighter-20,
        disabled-list-option:     $dark-bg-lighter-10,
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config

body {
    --primary-color: #5b6666;
    --primary-lighter-color: #ced1d1;
    --primary-darker-color: #404949;
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
        main: #5b6666,
        lighter: #ced1d1,
        darker: #404949,
        200: #5b6666, // For slide toggle,
        contrast : (
                main: $light-primary-text,
                lighter: $dark-primary-text,
                darker: $light-primary-text,
        )
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
    --accent-color: #bf0c25;
    --accent-lighter-color: #ecb6be;
    --accent-darker-color: #a90616;
    --text-accent-color: #{$light-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
        main: #bf0c25,
        lighter: #ecb6be,
        darker: #a90616,
        200: #bf0c25, // For slide toggle,
        contrast : (
                main: $light-primary-text,
                lighter: $dark-primary-text,
                darker: $light-primary-text,
        )
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
        main: #ff0000,
        lighter: #ffb3b3,
        darker: #ff0000,
        200: #ff0000, // For slide toggle,
        contrast : (
                main: $light-primary-text,
                lighter: $dark-primary-text,
                darker: $light-primary-text,
        )
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);;

$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
    @include angular-material-theme($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Sidenav
.mat-drawer-container {
    background: transparent;
}
// Tooltip
.mat-tooltip {
    font-size: $font-size-base;
}

/**
mat-chip
 */

.mat-standard-chip {
    height: auto !important;
}

// Mat tabs

.mat-tab-header {
    .mat-ink-bar {
        display: none;
    }

    .mat-tab-label {
        opacity: 1;

        &:hover {
            background: $gray-100;
        }

        &.mat-tab-label-active {
            // background: $gray-100;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-bottom: 0;

            .mat-tab-label-content {
                color: $dark-primary-text;
            }
        }

        .mat-tab-label-content {
            color: $link-color;
        }
    }
}

/*
Datatable
*/

$ngx-datatable-box-shadow: none;
$datatable-header-border-bottom-color: rgba(0, 0, 0, 0.12);
$datatable-header-resize-handle-color: rgba(0, 0, 0, 0.12);
$datatable-header-cell-color: rgba(0, 0, 0, 1);
$datatable-header-cell-background: rgba(0, 0, 0, 0.03);

$ngx-datatable-row-odd-background: #f9f9f9;
$ngx-datatable-default-background-hover: transparent;

@import '@swimlane/ngx-datatable/index.css';
@import '@swimlane/ngx-datatable/themes/material.scss';
@import '@swimlane/ngx-datatable/assets/icons.css';

.ngx-datatable.material {
    border-collapse: collapse;

    .datatable-header {
        border: 1px solid $datatable-header-border-bottom-color;
        border-bottom-width: 2px;
        border-left: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        .datatable-header-cell {
            border-left: 1px solid $datatable-header-border-bottom-color;
            font-size: $font-size-base;
        }
    }

    .datatable-body {

        .datatable-body-row {
            border: 1px solid $datatable-header-border-bottom-color;
            border-left: 0;
            border-top: 0;

            .datatable-body-cell {
                border-left: 1px solid $datatable-header-border-bottom-color;
                padding: $table-cell-padding;
            }
        }
    }

    .datatable-footer {
        border: 0;
    }
}

// Mat table
.mat-table {
    border-collapse: collapse;
    border: 1px solid #CCCCCC;
    margin: 0;

    .mat-header-row {
        height: 43px;
    }


    .mat-header-cell {
        border: 1px solid $datatable-header-border-bottom-color;
        border-bottom-width: 2px;
        border-left: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        background: $datatable-header-cell-background;
        text-align: center;
        font-size: $font-size-base;
        color: black;
        padding: 0;

        &:first-of-type {
            padding: 0;
        }

        &:last-of-type {
            padding: 0;
        }

        .mat-sort-header-container {
            justify-content: center;
        }
    }

    .mat-cell {
        border: 1px solid $datatable-header-border-bottom-color;
        padding: 4px;

        &:first-of-type {
            padding: 4px;
        }

        &:last-of-type {
            padding: 4px;
        }
    }
}

